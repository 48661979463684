import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from './instagram.css';

const InstagramList = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstaNode(sort: { fields: timestamp, order: DESC }) {
        edges {
          node {
            id
            caption
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Container>
        {data.allInstaNode.edges.map(edge => (
          <Fragment key={edge.node.id}>
            <div className="instagram-item">
              <figure>
                <Img
                  fluid={edge.node.localFile.childImageSharp.fluid}
                  alt={edge.node.caption}
                />
                <a
                  href={'https://www.instagram.com/p/' + edge.node.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="caption">{edge.node.caption}</span>
                </a>
              </figure>
            </div>
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export default InstagramList;
