import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  max-width: 1500px;
  margin: 0 auto;

  ${MEDIA.SMALLDESKTOP`
    grid-template-columns: repeat(2, 1fr);
  `};
  ${MEDIA.TABLET`
    grid-template-columns: repeat(1, 1fr);
  `};
  .instagram-item {
    figure {
      position: relative;
      display: grid;
      &:hover > a {
        opacity: 1;
      }
      a {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: center;
        justify-items: center;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        text-decoration: none;
        -webkit-transition: opacity 0.5s ease-in;
        -moz-transition: opacity 0.5s ease-in;
        -o-transition: opacity 0.5s ease-in;
        -ms-transition: opacity 0.5s ease-in;
        transition: opacity 0.5s ease-in;
        ${MEDIA.TABLET`
                  opacity: 1;
                  background-color: rgba(0, 0, 0, 0.7);
                `};
        span.caption {
          grid-area: 1 / 1 / 2 / 2;
          text-align: center;
          padding: 0 50px;
          line-height: 1.5;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-word-break: break-all;
        
          word-break: break-all;
          word-break: break-word;

          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
        }
      }
    }
  }
`;

export const Title = styled.h2`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 0;
`;
